// 将对应的禁用天数转化为字符串展示
export const numberToString = (number: Number) => {
  const t = (window as any).myI18n.global.t;
  let strArr = <any[]>[];
  let numArr = number.toString(2).padStart(7, '0').split('');
  console.log('numArr', numArr);
  numArr.forEach((item: any, index: number) => {
    if (item == '1') {
      switch (index + 1) {
        case 1:
          strArr.push(t('famikeep200.Every Sunday'));
          break;
        case 2:
          strArr.push(t('famikeep200.Every Monday'));
          break;
        case 3:
          strArr.push(t('famikeep200.Every Tuesday'));
          break;
        case 4:
          strArr.push(t('famikeep200.Every Wednesday'));
          break;
        case 5:
          strArr.push(t('famikeep200.Every Thursday'));
          break;
        case 6:
          strArr.push(t('famikeep200.Every Friday'));
          break;
        case 7:
          strArr.push(t('famikeep200.Every Saturday'));
          break;
      }
    }
  });

  console.log('strArr', strArr);
  return strArr;
};

// 将对应的禁用天数转化为字符串展示
export const numberToWeek = (number: Number) => {
  const t = (window as any).myI18n.global.t;
  let strArr = '';
  let numArr = number.toString(2).padStart(7, '0').split('');
  console.log('numArr', numArr);
  numArr.forEach((item: any, index: number) => {
    if (item == '1') {
      switch (index + 1) {
        case 1:
          strArr += 'Every Sunday ';
          break;
        case 2:
          strArr += 'Every Monday ';
          break;
        case 3:
          strArr += 'Every Tuesday ';
          break;
        case 4:
          strArr += 'Every Wednesday ';
          break;
        case 5:
          strArr += 'Every Thursday ';
          break;
        case 6:
          strArr += 'Every Friday ';
          break;
        case 7:
          strArr += 'Every Saturday ';
          break;
      }
    }
  });

  console.log('strArr', strArr);
  return strArr;
};

// 计算区间时间
export const calculateDuration = (startTime: any, endTime: any) => {
  console.log('startTime', startTime, endTime);

  const [startHours, startMinutes] = startTime.split(':');
  const [endHours, endMinutes] = endTime.split(':');

  const totalStartMinutes = parseInt(startHours) * 60 + parseInt(startMinutes);
  const totalEndMinutes = parseInt(endHours) * 60 + parseInt(endMinutes);

  let diffMinutes = totalEndMinutes - totalStartMinutes;
  if (diffMinutes < 0) {
    diffMinutes += 24 * 60; // Add 24 hours if end time is before start time
  }

  const hours = Math.floor(diffMinutes / 60);
  const minutes = diffMinutes % 60;

  console.log('hour', hours, minutes);

  return [hours, minutes];
};

// xx:xx => Wed Aug 16 2023 19:59:00
export const convertToCustomFormat = (time: string,TimeZone:any) => {
  const now = new Date();
  
  const [hours, minutes] = time.split(':');

  now.setHours(Number(hours));
  now.setMinutes(Number(minutes));

  const weekdays: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const day: string = weekdays[now.getDay()];
  const month: string = months[now.getMonth()];
  const date: number = now.getDate();
  const year: number = now.getFullYear();

  const customFormat: string = `${day} ${month} ${date} ${year} ${hours}:${minutes} ${TimeZone}`;
  return customFormat;
};

// 将Wed Aug 16 2023 19:59:00 转变成
export const formatToTime = (dateTimeString: string) => {
  const date = new Date(dateTimeString);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};

// 检查当前时间格式是Wed Aug 16 2023 19:59:00就返回true，是xx:xx就返回false
export const checkFormat = (timeStr: string) => {
  // if (/^[A-Za-z]{3}\s[A-Za-z]{3}\s\d{1,2}\s\d{4}\s\d{2}:\d{2}:\d{2}$/.test(timeStr)) {
  //   return true
  // } else if (/^\d{2}:\d{2}$/.test(timeStr)) {
  //   return false
  // }
  const date = new Date(timeStr);

  // 检查日期是否有效
  if (date.toString() === 'Invalid Date') {
    return false;
  } else {
    return true;
  }
};

// 将秒数转变为总时间xxhxxm
export function convertSecondsToHoursMinutes(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  const hoursPart = hours > 0 ? `${hours}h` : '';
  const minutesPart = minutes > 0 ? `${minutes}m` : '';

  return hoursPart + minutesPart;
}

// 将秒数转变为总时间xxhxxmxxs
export function convertSecondsToHoursMinutesSecond(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const second = Math.floor(seconds % 60);

  const hoursPart = hours > 0 ? `${hours}h` : '';
  const minutesPart = minutes > 0 ? `${minutes}m` : '';
  const secondPart = second > 0 ? `${second}s` : '';

  // console.log('---------',hoursPart,minutesPart,secondPart);

  return hoursPart + minutesPart + secondPart;
}
